@import '../common';

@mixin subtitle {
    font-family: europa, sans-serif;
}

.navigation {
    position: fixed;
    left: $port-space-sm;
    top: $port-space-md;
    width: 50px;
    height: 50px;
    cursor: pointer;
    z-index: 1000;
    transition: .2s;

    &.nav-up {
        transition: 
            transform .4s cubic-bezier(0.895, 0.03, 0.685, 0.22),
            opacity .4s cubic-bezier(0.895, 0.03, 0.685, 0.22);
        transform: translateY(-50px);
        opacity: 0;
    }

    &.nav-down {
        transition: 
            transform .7s cubic-bezier(0.165, 0.84, 0.44, 1),
            opacity .7s cubic-bezier(0.165, 0.84, 0.44, 1);
        transform: translateY(0);
        opacity: 1;
    }

    &:hover, &.navBack {
        transform: translateY(-2px);
    }
}

.ham-menu {
    height: 4px;
    width: 60%;
    background-color: black;
    position: absolute;
    cursor: pointer;
    top: 30%;
    transition: .4s all cubic-bezier(0.77, 0, 0.175, 1);
    border-bottom: 1px solid white;

    &:before {
        content: "";
        position: absolute;
        height: 4px;
        width: 40px;
        background-color: black;
        bottom: -12px;
        transition: .4s all cubic-bezier(0.77, 0, 0.175, 1);
        left: 0;
        border-bottom: 1px solid white;
    }
    
    &:after {
        content: "";
        position: absolute;
        height: 4px;
        width: 20px;
        background-color: black;
        bottom: 10px;
        transition: .4s all cubic-bezier(0.77, 0, 0.175, 1);
        left: 0;
        border-bottom: 1px solid white;
    }

    &.navBack {
        border-bottom: 1px solid transparent;
        height: 3px;

        &:after {
            transform: rotate(-45deg) translateX(-6px) translateY(-.5px);
            border-bottom: 1px solid transparent;
            height: 3px;
        }
        &:before {
            transform: rotate(45deg) scaleX(0.5) translateX(-29px) translateY(7.1px);
            border-bottom: 1px solid transparent;
            height: 3px;
        }
    }
}

// Menu Styles

.navigation-list {
    position: fixed;
    height: 105vh;
    width: 100vw;
    background-color: #fff;
    z-index: 998;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: $port-space-sm;
    top: -2.5vh;
    margin: 0;

    // Item Styling

    .menu-item {
        display: block;
        width: 100%;
        color: #ccc;
        font-weight: 200;
        font-size: 2rem;
        cursor: pointer;
        margin-bottom: 20px;
        transform: translateY(40px);
        opacity: 0;
    
        & span {
            overflow: hidden;
            display: inline-block;
            white-space: nowrap;
            line-height: 1;
            padding-bottom: $port-space-sm;
    
            &.hidden {
                transition: 0.5s all cubic-bezier(0.77, 0, 0.175, 1);
                color: #ccc;
                display: none;
            }
        }
      
        &:hover span {
            color: #333;
            width: auto;
        }
    }

    // Fade in menu items when navigation is opened

    &.open {
        @for $i from 1 through 5 { 
            .menu-item:nth-child(#{$i}) {
                transition: .5s #{$i*.05 + .9}s all cubic-bezier(0.165, 0.84, 0.44, 1);
            }
        }
        .menu-item { 
            transform: translateY(0);
            opacity: 1;
        }
    }

    &.closing {
        @for $i from 1 through 5 { 
            .menu-item:nth-child(#{$i}) {
                transition: .4s #{(.25 - $i*.05)}s all cubic-bezier(0.165, 0.84, 0.44, 1);
            }
        }

        .menu-item {
            transform: translateY(40px);
            opacity: 0;
        }
    }
}

// Fading In Menu

.nav-list {
    position: absolute;
    &::-webkit-scrollbar { width: 0 !important; background: transparent; }
  }
  
  .nav-list-enter {
    opacity: 0;
    pointer-events: none;
  }
  
  .nav-list-enter-active {
    opacity: 1;
    transition: opacity 800ms cubic-bezier(0.165, 0.84, 0.44, 1) 200ms;
  }
  
  .nav-list-exit {
    opacity: 1;
  }
  
  .nav-list-exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }

  .nav-list-exit-done {
    opacity: 0;
    pointer-events: none;
  }

@media (min-width: $port-breakpoint-sm) {
    .navigation-list .menu-item {
        font-size: 2rem;
    }
    .navigation-list .menu-item span.hidden {
        display: none;
    }
}

@media (min-width: $port-breakpoint-md) {
    .navigation {
        top: $port-space-lg;
        left: $port-space-lg;
    }

    .navigation-list {
        padding-left: $port-space-lg;
    }

    .navigation-list .menu-item span.hidden {
        display: none;
    }

    .navigation-list .menu-item {
        font-size: 2.5rem;
    }
}

@media (min-width: $port-breakpoint-xmd) {
    .ham-menu {
        transform: translateX(5px) translateY(9px);
    }

    .navigation-list .menu-item span.hidden {
        display: inline-block;
    }

    .navigation-list {
        padding-left: $port-space-lg + .3rem;
    }
}


