@import '../common';

// Hero Image

.project-hero {
    width: 100vw;
    height: 300px;
    overflow: hidden;
    margin-bottom: $port-space-xlg;
    display: none;

    &-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &.no-gradient::before {
        background: none;
    }
}

// Project Title

.project-title {
    margin-left: $port-space-sm;
    margin-top: $port-space-xxlg*2.5;
    margin-bottom: $port-space-xxlg;
    position: relative;
    width: calc( 100% - 2rem);

    &::after {
        content: "";
        position: absolute;
        bottom: 0rem;
        left: 0;
        width: 30px;
        height: 3px;
        background-color: black;
        transform: translateY(1rem) translateX(1px);
    }
}

// Post Back Link

.project-back-link-container-default {
    text-align: center;

    .project-back-link {
        position: relative;
        display: inline-block;

        &::before {
            content: "⟵";
            position: absolute;
            top: 50%;
            transform: translateX(-130%) translateY(-50%);
        }
    }
}

.project-back-link-container-fixed {
    display: none;
}

.project-back-link {
    display: block;
    width: 100vw;
    text-align: center;
    margin: $port-space-lg 0 $port-space-xlg 0;
}

// Content

.project-container {
    box-sizing: border-box;
    width: 100vw;
    padding: 0 $port-space-sm;
    overflow: hidden;

    a {
        // color: #666;
        font-weight: 500;
        position: relative;

        // &:visited {
        //     color: #666;
        // }
    
        &:hover {
            color: #000;
        }
    }

    > h2 {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }

    > h3 {
        margin-top: 3rem;
        margin-bottom: 2rem;
    }

    > h4 {
        margin-top: 3rem;
        margin-bottom: 1.5rem;
    }

    .chatbot-motion figure{
        background-color: #ededed;
        overflow: hidden;
        
        img {
            transform: scale(1.01);
        }
    }

    figure {
        margin: 0;
    }

    img {
        width: 100%;
        margin: 0 auto;
        display: block;
        flex: 1;
        height: 100%;
        object-fit: cover;
    }

    cite {
        line-height: 1.5;
        display: block;
        text-align: right;
    }

    figcaption {
        line-height: 1.5;
        margin-top: 1rem;
        color: #aaa;
        text-align: center;
    }

    .wp-block-video {

        &.chatbot-motion video {
            background-color: #f2f2f2;
        }

        video {
            width: 100%;
            max-height: 60vh;
        }    

        figcaption {
            margin-top: 1rem;
            color: #aaa;
        }
    }

    ul, ol {
        width: 100%;
        padding-left: 1rem;
    }

    // Image Layouts

    // Regular Images

    .wp-block-image {
        margin: 0 0 2rem 0; 
        display: flex;
        flex-direction: column;

        > figcaption {
            order: 2;
            margin-top: 16px;
            color: #aaa;
        }

        > a {
            order: 1;
        }

        &.banner-img img {
            max-height: 400px;
        }

        &.small-gif img {
            max-height: 300px;
            object-fit: contain;
        }
    }

    // Side by side images

    .wp-block-gallery {
        display: flex;
        padding: 0;
        flex-direction: column;
        width: 100%;

        .blocks-gallery-item figure {
            display: flex;
            flex-direction: column;
            width: 100%;

            figcaption {
                order: 1;
                font-size: 1rem;
                color: #aaa;
            }

            a {
                order: 0;
            } 
        }
    }

    @media (min-width: rem(800px)) {
        .wp-block-gallery {
            flex-direction: row;
            .blocks-gallery-item figure {
                flex: none;
                width: auto;
            }
        }
    }

    .wp-block-gallery.columns-2 {
        width: 100%;
        list-style-type: none;
        padding: 0;
        flex-direction: column;
        overflow: hidden;
    }

    .blocks-gallery-item {
        overflow: hidden;
        margin-bottom: $port-space-sm;
    }

    // Media & Text Combo

    .wp-block-media-text {
        display: flex;
        flex-direction: column;

        ul, ol {
            width: 100%;
        }
    }

    blockquote {
        position: relative;

        &::before {
            content: "“";
            font-family: Helvetica, Arial, sans-serif;
            position: absolute;
            top: 20px;
            left: -23px;
            transform: scale(4);
            color: #BBB;
        }
    }
}

.main-content-trans-enter {
    opacity: 0;
}

.main-content-trans-enter-active {
    opacity: 1;
    transition: opacity 200ms;
}

.main-content-trans-exit {
    opacity: 1;
}

.main-content-trans-exit-active {
    opacity: 0;
    transition: opacity 200ms;
}

@media only screen and (min-width: $port-breakpoint-sm) {
    .wp-block-image {
        margin: $port-space-lg 0 $port-space-xlg 0;
    }
}

@media (min-width: $port-breakpoint-md) {

    .project-container {
        padding: 0 $port-space-lg;

        p {
            margin-bottom: $port-space-lg;
        }

        .wp-block-gallery.columns-2 {
            margin: $port-space-lg 0 $port-space-xlg 0;
        }
    }

    .project-title {
        margin-left: $port-space-lg;
        width: calc( 100% - 4rem);
        margin-top: $port-space-xxlg*2 + 2rem;
    }
}

@media (min-width: rem(800px)) {

    .project-container {
        p {
            max-width: 50%;
            line-height: 1.75;
        }

        p.codepen {
            max-width: 100%;
        }

        .wp-block-media-text .wp-block-media-text__content p {
            max-width: 100%;
        }

        ul, ol {
            width: 50%;
        }

        img {
            outline: 1px solid #eee;
        }

        .wp-block-gallery.columns-2 {
            flex-direction: row;
            flex: 1;
            margin: $port-space-lg 0 $port-space-xlg 0;
        }

        .wp-block-image {
            margin: $port-space-lg 0 $port-space-xlg 0;
        }

        .blocks-gallery-item + .blocks-gallery-item {
            margin-left: $port-space-md
        }

        .wp-block-media-text {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            margin: $port-space-lg 0 $port-space-xlg 0;

            img {
                min-width: 100%;
                margin: 0 auto;
                display: block;
                flex: 1;
                min-height: 100%;
                object-fit: contain;
                max-height: 400px;
            }

            & > .wp-block-media-text__media, & > .wp-block-media-text__content {
                flex: 1;
            }

            & > .wp-block-media-text__media + .wp-block-media-text__content,
            & > .wp-block-media-text__content + .wp-block-media-text__media {
                margin-left: $port-space-md;
            }

            .wp-block-media-text__content p {
                margin-top: 0;
            }
            
            &.has-media-on-the-right {
                > .wp-block-media-text__content {
                    order: 0;
                    margin-left: 0;
                }

                > .wp-block-media-text__media {
                    order: 1;
                    margin-left: $port-space-md;
                }
            }
        }

        .wp-block-columns {
            display: flex;
            flex-direction: row;
    
            .wp-block-column {
                flex: 1;
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;

                p {
                    max-width: 100%;
                }

                & + .wp-block-column {
                    margin-left: 8rem;
                }

                ul, ol {
                    width: 100%;
                }

                .wp-block-image {
                    &.limit-height img {
                        max-height: 300px;
                        object-fit: contain;
                    }
                }

                .wp-block-quote {
                    margin-bottom: 2rem;

                    &::before {
                        transform: scale(5);
                        top: 35px;
                        left: -32px;
                    }

                    p {
                        margin-bottom: 1rem;
                    }
                }
            }

            &.three-cols .wp-block-column + .wp-block-column {
                margin-left: 4rem;
            }

            &.columns-top-aligned .wp-block-column {
                justify-content: flex-start;
            }
        }

        .text-emphasis h2 {
            padding: 0 5rem;
            line-height: 1.5;
        }
    }

}

@media (min-width: rem($port-wrapper)) {
    // Wrapper Styles

    .project-container, .project-title {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .project-container:not(.home-project-container){
        max-width: rem($port-wrapper);
    }

    .home-project-container {
        max-width: rem(1100px);
    }

    // Hero 

    // hide mobile header
    
    .project-title {
        display: none;
    }

    .project-hero {
        position: relative;
        height: 400px;
        display: block;
        z-index: 1;

        &::before {
            content: "";
            height: 100%;
            width: 100%;
            z-index: 1;
            position: absolute;
            top: 0;
            left: 0;
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
            background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
            /* FF3.6-15 */
            background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
            /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
            /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6000000', GradientType=0);
            /* IE6-9 */

        }
    }

    .project-title-hero {
        color: white;
        z-index: 1;
        position: absolute;
        width: 100vw;
        bottom: 0;
        font-size: 2rem;
        font-weight: 400;
        margin-bottom: $port-space-xlg;
    }

    .project-title-hero-text {
        padding-left: $port-space-lg;
        display: block;
        max-width: rem($port-wrapper);
        margin: 0 auto;
    }

    .project-back-link {
        transition: transform .6s cubic-bezier(0.165, 0.84, 0.44, 1);
        transform: translateX(0%);
        width: initial;
        width: auto;

        &.show {
            transform: translateX(0%);
            width: initial;
            width: auto;
        }
    }

    // &.pinned {
    //     position: fixed;
    //     left: 2.4rem;
    //     top: 4rem;
    //     transform-origin: 0 50%;
    //     transform: rotate(90deg);
    // }

    // Pinned link to all projects

    .project-back-link-container-fixed  {
        position: fixed;
        overflow: hidden;
        display: none;
        z-index: 0;

        @media (min-width: 1250px) {
            display: block;
        }

        .project-back-link {   
            transition: all .6s cubic-bezier(0.165, 0.84, 0.44, 1); 
            transform: translateX(-100%);
            opacity: 0;
            width: initial;
            width: auto;

            &.show {
                transform: translateX(0%);
                opacity: 1;
                width: initial;
                width: auto;
            }
        }

        &.pinned {
            left: 2.4rem;
            top: 4rem;
            transform-origin: 0 50%;
            transform: rotate(90deg);
        }
    }
}

// FEATHERLIGHT PLUGIN

.featherlight img {
    max-width: 85vw;
    max-height: 70vh;
    object-fit: contain;
    width: 100%;
}

.featherlight-content {
    overflow-y: hidden !important;
}

@media (min-width: $port-breakpoint-md){
    .featherlight img {
        max-height: 90vh;
    }
}



