@import './common';

#root {
    height: 100vh;
}

.App {
    width: 100vw;
    overflow: hidden;

    &-content {
        min-height: calc(100vh - 48px);
    }
}

.home-project-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: $port-space-xlg*2;
    padding-bottom: $port-space-xlg*2;
}

.project {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 12rem;

    img {
        margin: 0;
    }
}

.skeleton {
    background-color: #f3f3f3;
    border-radius: 4px;
    position: relative;
        
    &::after {
        content: "";
        position: absolute;
        height: 100%;
        left: -50%;
        width: 50%;
        background: rgb(255,255,255);
        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 40%, rgba(255,255,255,0.5) 60%, rgba(255,255,255,0) 100%);
        animation: 2s shimmer 1s cubic-bezier(0.86, 0, 0.07, 1) infinite;
    }
}

@keyframes shimmer {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(300%);
    }
}

.home-project-title {
    display: flex;
    margin-right: 15%;

    .skeleton {
        height: 1rem;
        width: 250px;
    }
}

.home-project-title a {
    font-size: rem(22px);
    line-height: 1.3;
    font-weight: 500;
    color: #222;
    // color: #444;
    // white-space: nowrap;
    // text-overflow: ellipsis;
    overflow: hidden;
}

.project-details-container{
    display: flex;
    flex-direction: column;
}

.project-metadata {
    font-size: 16px;
    font-weight: 500;
    order: -1;
    color: #999;
}

.project-link-container {
    // order: 1;
    // margin-top: 1.75rem;
    display: none;
}

.project-image-container {
    border-radius: 20px;
    box-shadow: 0 0 0px 0 rgba(0,0,0,.1);
    height: 63vw;
    overflow: hidden;
    border: 2px solid #eee;
    transition: all .4s cubic-bezier(0.86, 0, 0.07, 1);

    &.skeleton {
        box-shadow: none;
        overflow: hidden;
        border: 1px solid transparent;

        img {
            opacity: 0;
        }
    }

    img {
        transition: opacity .4s 1s;
        opacity: 1;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.project-to-link {
    margin-top: 20px;
    color: #222;

    .skeleton {
        height: 1rem;
        width: 250px;
    }
}

@media (min-width: 400px) {
    .home-project-title {
        margin-top: rem(8px);
    }

    .home-project-title a {
        font-size: rem(22px);
    }
}

@media (min-width: 550px) {

    .home-project-title {
        margin-top: rem(16px);
    }


    .home-project-title a {
        font-size: rem(28px);
    }

    .project-metadata {
        font-size: rem(22px);
    }
}

@media (min-width: 700px) {
    .home-project-title {
        margin-right: 25%;
    }

    .home-project-title a {
        font-size: rem(32px);
    }
}

@media (min-width: 825px) {
    .home-project-title a {
        font-size: rem(36px);
    }
}

// @media (min-width: $port-breakpoint-md) {
@media (min-width: 950px) {
    .project {
        // width: calc( 50% - #{$port-space-xlg / 2} );
        flex-direction: row;
        width: 100%;
        max-height: rem(500px);
        margin-bottom: rem(300px);
        
        // &:nth-child(even) {
        //     margin-left: $port-space-xlg;
        // }

        .project-details-container {
            flex: 1;
            margin-right: 0%;
            display: flex;
            flex-direction: column;
            flex: 1 1;
            margin-top: -20px;
            justify-content: center;
        }

        .project-metadata {
            order: 0;
            margin-bottom: rem(24px);
        }

        .home-project-title {
            margin-bottom: rem(16px);
        }

        .home-project-title > a {
            font-size: rem(40px);
            text-wrap: wrap;
            width: 100%;
        }

        .project-image-container {
            max-height: 100%;
        }

        .project-image-container {
            transition: .55s all cubic-bezier(0.165, 0.84, 0.44, 1);
            flex: 1;

            img {
                transition: .2s transform cubic-bezier(0.25, 1, 0.5, 1);
            }
        }



        &:hover {
            .project-image-container {
                // transform: scale(1.02) translateY(-5px);
                // box-shadow: 0 40px 40px 5px rgba(0,0,0,.15);

                img {
                    transform: scale(1);
                }
            }

            .project-to-link, .home-project-title a {
                color: #222;
            }

            .project-link {
                transform: translateX(0) scale(1);
                opacity: 1;
            }  
        }
    }

    .project-container {
        overflow: initial;
    }

    .project-link-container {
        display: block;
    }

    .project-link {
        transition: .3s all cubic-bezier(0.19, 1, 0.22, 1);
        transform: translateX(-125px) scale(.7);
        opacity: 0;
    }
}

@media (min-width: 1150px) {

    .project {
        // min-width: 0px;
        // flex-basis: calc((100% - 80px) / 3);
        // width: 100%;
        flex-grow: grow;
        // margin-left: $port-space-xlg;

        // &:nth-child(even) {
        //     margin-left: $port-space-xlg;
        // }
        
        // &:nth-child(3n+1) {
        //     margin-left: 0;
        // }

        .project-details-container {
            margin-top: rem(20px);
            justify-content: start;
        }

        .project-image-container {
            flex: 1.5;
        }

        .home-project-title {
            margin-right: 15%;
        }

        .project-link-container {
            display: block;
        }

        .project-link {
            transition: .3s all cubic-bezier(0.19, 1, 0.22, 1);
            transform: translateX(-125px) scale(.7);
            opacity: 0;
        }

        &:hover {
            .project-link {
                transform: translateX(0) scale(1);
                opacity: 1;
            }  
        }

    }
}