@import '../common';

.project-container.about-content {
    margin-top: 5rem;
    padding: 3rem 2rem 8rem;
}

.project-container.about-content p {
    max-width: rem(518px);
    margin-left: auto;
    margin-right: auto;
}

.highlight {
    color: black;
}

.project-container.about-content .email {
    margin: rem(25px) auto;
    text-align: center;
}

.project-container.about-content .emphasis {
    max-width: rem(518px);
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-bottom: $port-space-xxlg;
    line-height: 1.5;
    font-weight: 400;
}

.project-container.about-content .about-pic {
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
}

.project-container.about-content .about-pic img {
    border-radius: 1000px;
    overflow: hidden;
    object-fit: cover;
    box-shadow: 0 20px 60px 0px rgba(0,0,0,.3);
    height: 100px;
    width: 100px;
    flex: none;
    outline: none;
}

@media (min-width: rem(800px)) {
    .project-container.about-content .about-pic img {
        height: 125px;
        width: 125px;
    }
}
