@import '../common';

.password-wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    background-color: #eee;
    top: 0;
    left: 0;
    z-index: 1;
}

.password-trans-enter {
    opacity: 0;
}

.password-trans-enter-active {
    opacity: 1;
    transition: opacity 200ms;
}

.password-trans-exit {
    opacity: 1;
}

.password-trans-exit-active {
    opacity: 0;
    transition: opacity 200ms;
}

.password-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .incorrect-text {
        margin-top: 1rem;
    }
}

.password-input-container {
    position: relative;
}

.password-input {
    height: $port-space-xxlg;
    text-indent: 1rem;
    width: 20rem;
    outline: 2px solid #ccc;
    outline-offset: 0;
    border: 0;
    transition: .2s outline;

    &:focus {
        outline: 2px solid #222;
        outline-offset: 0;
    }
}

.password-submit {
    visibility: hidden;
    opacity: 0;
    transition: .4s ease-in-out;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    padding: 0;

    &.submit-available {
        visibility: visible;
        opacity: .4;
        outline: 2px solid transparent;
        outline-offset: 0;
        border: 0;
        transition: .2s;

        &:focus {
            opacity: 1;
            outline: 2px solid #222;
            outline-offset: 0;
        }

        &:hover {
            opacity: 1;
        }
    }
}

.lock-image {
    opacity: .5;
    margin-bottom: 1rem;
    height: 48px;
    width: 48px;
}