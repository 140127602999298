@import '../common';

.footer {

    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;

    &-icon-link {

        &:hover {
            color: #aaa;
        }

        margin: 0 $port-space-sm;

        &-linkedin:hover svg {
            fill: #0084bf;
        }
        &-dribbble:hover svg {
            fill: #ea4c89;
        }
        &-email:hover svg {
            fill: #000;
        }

        svg{
            width: 24px;
            transition: fill .2s linear;
        }
    }
}